@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  display: flex;
  align-items: left;
  max-width: 1600px;
  border-bottom: 1px solid #f2f2f2;
}
.head {
  color: #f1356d;
  margin-left: auto;
}
.navbar a:hover {
  color: #f1356d;
}
.content {
  max-width: 2000px;
  margin: 40px auto;
  padding: 20;
}
.cardtext{
  color: blue;
}